import {
  PURCHASABLE_ITEMS_BEGIN_FETCH,
  PURCHASABLE_ITEMS_END_FETCH,
  PROMO_PURCHASABLE_ITEMS_BEGIN_FETCH,
  PROMO_PURCHASABLE_ITEMS_END_FETCH,
  BALANCE_END_FETCH,
  MINTROUTE_PURCHASABLE_ITEMS_BEGIN_FETCH,
  MINTROUTE_PURCHASABLE_ITEMS_END_FETCH,
  MINTROUTE_SET_SUSPENDED_STATUS,
  VIP_PURCHASABLE_ITEMS_BEGIN_FETCH,
  VIP_PURCHASABLE_ITEMS_END_FETCH,
  ACME_RECEIVED,
  SPECIAL_PURCHASABLE_ITEMS_BEGIN_FETCH,
  SPECIAL_PURCHASABLE_ITEMS_END_FETCH,
  REMOVE_PROMO_OFFER,
  PROMO_WAS_BOUGHT,
  PROMO_OFFER_VIEWED,
} from "state/actionTypes";
import withFetcher, {
  fetcherSelectors,
  createFetcherActions,
} from "state/hor/withFetcher";
import noopReducer from "./utils/noopReducer";
import { ensureHttps } from "src/utils/ensureHttps";
import { makeWithUserSessionScope } from "state/hor/withUserSessionScope";
import { createMigrate } from "redux-persist";
import delegateSelectors from "state/tree/utils/delegateSelectors";
import { mapValues } from "src/utils/miniLodash";

export const persistConfig = {
  whitelist: ["list", "version"],
};

export const mintroutePersistConfig = {
  whitelist: ["list", "suspended", "version"],
};

export const migrations = {
  0: (state) => ({
    promoOffers: mapValues(
      state.promoOffers,
      (promo) => (promo ? [promo] : []),
      {
        omitUndefined: true,
      }
    ),
  }),
};

export const specialShopPersistConfig = {
  whitelist: ["promoOffers"],
  version: 0,
  migrate: createMigrate(migrations),
};

export const shopActionCreators = {
  ...createFetcherActions({
    beginFetchActionType: PURCHASABLE_ITEMS_BEGIN_FETCH,
    endFetchActionType: PURCHASABLE_ITEMS_END_FETCH,
  }),
};

export const promoShopActionCreators = createFetcherActions({
  beginFetchActionType: PROMO_PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: PROMO_PURCHASABLE_ITEMS_END_FETCH,
});

export const vipShopActionCreators = createFetcherActions({
  beginFetchActionType: VIP_PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: VIP_PURCHASABLE_ITEMS_END_FETCH,
});

export const mintrouteShopActionCreators = {
  ...createFetcherActions({
    beginFetchActionType: MINTROUTE_PURCHASABLE_ITEMS_BEGIN_FETCH,
    endFetchActionType: MINTROUTE_PURCHASABLE_ITEMS_END_FETCH,
  }),
  setSuspendedStatus: (status) => ({
    type: MINTROUTE_SET_SUSPENDED_STATUS,
    payload: status,
  }),
};

export const specialShopActionCreators = {
  ...createFetcherActions({
    beginFetchActionType: SPECIAL_PURCHASABLE_ITEMS_BEGIN_FETCH,
    endFetchActionType: SPECIAL_PURCHASABLE_ITEMS_END_FETCH,
    funcNamePrefix: "promo",
  }),
  removePromoOffer: (config) => ({
    type: REMOVE_PROMO_OFFER,
    payload: config,
  }),
  promoWasBought: (config) => ({
    type: PROMO_WAS_BOUGHT,
    payload: config,
  }),
  promoOfferViewed: (config) => ({
    type: PROMO_OFFER_VIEWED,
    payload: config,
  }),
};

export const transformOffer = ({ currencyPrice = [], image, ...rest }) => ({
  ...rest,
  image: ensureHttps(image),
  currencyPrice: currencyPrice.reduce(
    (a, { currency, price }) => {
      a[currency.toUpperCase()] = price / 100;
      return a;
    },
    { USD: rest.usd }
  ),
});

const makeReducer = ({
  beginFetchActionType,
  endFetchActionType,
  additionalActionsHandler = noopReducer,
  initialData = {
    list: [],
    isStale: true,
    version: "",
  },
}) =>
  withFetcher({
    beginFetchActionType,
    endFetchActionType,
    initialData,
    extractData: (list, { version }) => ({
      list: list.map(transformOffer),
      isStale: false,
      version,
    }),
    mergeData: (_, newData) => newData,
  })(additionalActionsHandler);

export const shopReducer = makeReducer({
  beginFetchActionType: PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: PURCHASABLE_ITEMS_END_FETCH,
  additionalActionsHandler: makeWithUserSessionScope(({ state }) => ({
    ...state,
  }))((state, action) => {
    if (action.type === BALANCE_END_FETCH) {
      if (action.error) {
        return state;
      }

      const { vipStatus, coinsLeft, vipLevelInfo } = action.payload;

      return {
        ...state,
        vipInfo: {
          status: vipStatus,
          coinsLeft,
          vipLevelInfo,
        },
      };
    }
    return state;
  }),
});

export const promoShopReducer = makeReducer({
  beginFetchActionType: PROMO_PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: PROMO_PURCHASABLE_ITEMS_END_FETCH,
});

export const vipShopReducer = makeReducer({
  beginFetchActionType: VIP_PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: VIP_PURCHASABLE_ITEMS_END_FETCH,
});

export const mintrouteShopReducer = makeReducer({
  beginFetchActionType: MINTROUTE_PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: MINTROUTE_PURCHASABLE_ITEMS_END_FETCH,
  additionalActionsHandler: makeWithUserSessionScope(({ state }) => ({
    ...state,
    suspended: false,
  }))((state, action) => {
    switch (action.type) {
      case MINTROUTE_SET_SUSPENDED_STATUS: {
        return { ...state, suspended: action.payload };
      }
    }
    return state;
  }),
});

export const specialPromoReducer = makeReducer({
  beginFetchActionType: SPECIAL_PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: SPECIAL_PURCHASABLE_ITEMS_END_FETCH,
  initialData: {
    list: [],
    isStale: true,
    version: "",
  },
});

const withUserSessionScope = makeWithUserSessionScope(() => ({}));

export const promoOffersReducer = withUserSessionScope((state = {}, action) => {
  switch (action.type) {
    case ACME_RECEIVED: {
      const { serviceName, serviceIdentifier, data } = action.payload;
      if (serviceName === "gift" && serviceIdentifier === "offerAvailable") {
        return {
          ...state,
          [data.flags]: [...(state[data.flags] || []), data],
        };
      }
      return state;
    }
    case REMOVE_PROMO_OFFER: {
      if (action.payload) {
        const { flag, offerSku } = action.payload;
        return {
          ...state,
          [flag]: state[flag].filter((promo) => promo.offer_sku !== offerSku),
        };
      }
      return state;
    }
    case PROMO_WAS_BOUGHT: {
      if (action.payload) {
        const { flag, offerSku } = action.payload;
        const currentPromo = state[flag].find(
          (promo) => promo.offer_sku === offerSku
        );
        if (currentPromo) {
          const previousTimes = Number(currentPromo.times || 0);

          if (previousTimes !== -1) {
            return {
              ...state,
              [flag]:
                previousTimes > 1
                  ? state[flag].map((promo) =>
                      promo.offer_sku === offerSku
                        ? { ...promo, times: previousTimes - 1 }
                        : promo
                    )
                  : state[flag].filter((promo) => promo.offer_sku !== offerSku),
            };
          }
        }
      }

      return state;
    }
    case PROMO_OFFER_VIEWED: {
      if (action.payload) {
        const { flag, offerSku } = action.payload;
        return {
          ...state,
          [flag]: state[flag].map((promo) =>
            promo.offer_sku === offerSku ? { ...promo, isViewed: true } : promo
          ),
        };
      }
      return state;
    }
  }
  return state;
});

const commonSelectors = {
  ...fetcherSelectors,
  getListOfPurchasableItems: (state) => state.list,
  getIsStale: (state) => state.isStale,
  getVersion: (state) => state.version,
};

export const selectors = {
  ...commonSelectors,
  getVipInfo: (state) => state.vipInfo,
};

export const mintrouteShopSelectors = {
  ...commonSelectors,
  getIsSuspended: (state) => state.suspended,
};

const specialOffersSelectors = delegateSelectors(
  commonSelectors,
  (state) => state.specialOffers
);

export const specialShopSelectors = {
  ...specialOffersSelectors,
  getPromoOffers: (state) => state.promoOffers,
};
