import { batch } from "react-redux";
import { analyticsUpgradeGuest } from "src/core/analytics/utils/analyticsUpgradeGuest";
import { setMarketingVisitData } from "src/features/marketing/exports/state";
import { updateAnalyticsKey } from "src/features/marketing/state/slice";
import {
  autoLogin,
  fetchAllowedCredentialTypes,
  registerGuest,
  registerGuestForAutologin,
  registerVisitor,
  upgradeGuest,
} from "src/features/signin/api/login";
import { referralRegistration } from "src/features/signin/api/referrals";
import { validation } from "src/features/signin/api/validation";
import {
  EventCategory,
  EventFields,
  EventNames,
  EventReasons,
  RegistrationSource,
  SCREEN_NAME,
  UtmParams,
  emitAF,
  emitEvent,
  emitReasonAction,
} from "src/features/signin/imports/analytics";
import { CLIENT_CAPABILITIES } from "src/features/signin/imports/constants";
import {
  AutologinResult,
  DeviceType,
  LoginProvider,
  LoginResult,
  ModalType,
  SignUpEntryPoint,
  ToastType,
} from "src/features/signin/imports/enums";
import {
  getAppleProviderEnabled,
  getFacebookProviderEnabled,
  getGoogleProviderEnabled,
  getReferralRegistrationEnabled,
} from "src/features/signin/imports/environment";
import {
  FROM_FLOW,
  HTTP_CODE_FORBIDDEN,
  HTTP_CODE_UNAUTHORIZED,
  addToast,
  checkInVisitor,
  clearVisitorData,
  deviceInfoSelectors,
  dismissModalWithType,
  fetchSessionDetails,
  hasValidAccount,
  loadMyProfile,
  lockTopModal,
  loginSelectors,
  openRegistrationDisabledModal,
  refreshSessionToken,
  retryAfterCaptcha,
  setAutoLoginResult,
  setCaptchaConfig,
  setDropdownAvatarState,
  setDropdownMenuState,
  startAutologin,
  unlockTopModal,
  userSelectors,
  validationEnd,
} from "src/features/signin/imports/state";
import {
  AUTOLOGIN_QUERY_KEYS,
  CaptchaType,
  sharedMessages,
} from "src/features/signin/imports/ui";
import {
  cleanLocalStorageByKeys,
  cleanupQueryWithKeys,
  emitImpactSignUpCompleted,
  getOsVersion,
  isLockedByCaptcha,
} from "src/features/signin/imports/utils";
import { openLoginView } from "src/features/signin/state/flows/openLoginView";
import { obtainFacebookCredential } from "src/features/signin/state/flows/utils/facebookHelpers";
import {
  askForSms,
  authorizationFailed,
  authorizationStart,
  authorizationSuccess,
  beganPreparingProviders,
  endedPreparingProviders,
  failedToLogoutFromProvider,
  loadedLoginProvider,
  loginFailed,
  loginSetEntryPoint,
  loginStart,
  loginSuccess,
  logoutFailed,
  logoutStart,
  logoutSuccess,
  phoneLoginAttemptLimitReached,
  phoneLoginMethodUnavailableForRegisteredUser,
  phoneLoginPhoneVerificationUnavailable,
  phoneLoginSetSmsResendDelay,
  phoneLoginVerificationFailed,
  phoneLoginVerificationRequired,
  phoneLoginVerificationRequiredSMSNotPossible,
  setSessionLimited,
  specifyPhoneNumberForPhoneLogin,
} from "src/features/signin/state/login/actionCreators";
import mapProviderToApi from "src/features/signin/utils/mapProviderToApi";

const registrationSelector = (state) => ({
  fingerprint: deviceInfoSelectors.getDeviceFingerprint(state),
  locale: deviceInfoSelectors.getDeviceLocale(state),
});

const LOCAL_STORAGE_KEYS = [UtmParams.UTM_CAMPAIGN];

export const sendReferralRegistrationRequest = async (getState) => {
  if (!getReferralRegistrationEnabled()) {
    return;
  }
  const state = getState();
  const referrerAccountId = loginSelectors.getReferrerAccountId(state);
  const isGuest = !loginSelectors.isLoggedIn(state);
  if (!referrerAccountId) {
    return;
  }
  try {
    await referralRegistration(referrerAccountId);
  } catch (ignored) {
    //
  }
  if (isGuest) {
    emitEvent(EventNames.AGENT_GUEST_REGISTRATION, {
      guest_id: userSelectors.getMyAccountId(state),
      referral_id: referrerAccountId,
    });
  }
};

const authorizeGuestSelector = (state) => ({
  hasValidAccount: hasValidAccount(state),
  isAuthorizationInProgress: loginSelectors.isAuthorizationInProgress(state),
});

export const authorizeGuestV2 =
  ({ accountId, token }) =>
  (dispatch, getState) => {
    const state = getState();
    dispatch(authorizationStart());

    const { fingerprint, locale } = registrationSelector(state);

    return registerGuestForAutologin({
      clientCapabilities: CLIENT_CAPABILITIES,
      fingerprint,
      locale,
      osVersion: getOsVersion(),
      clientVersion: GENERATED_APP_INFO.fullVersion,
      limitedSessionCredentials: {
        accountId,
        authToken: token,
      },
    })
      .then((data) => {
        const { accountId, ...connectionManagerData } = data;

        batch(() => {
          dispatch(
            authorizationSuccess({
              accountId,
              connectionManagerData,
            })
          );
          dispatch(fetchSessionDetails());
        });

        sendReferralRegistrationRequest(getState);

        return data;
      })
      .catch((error) => {
        dispatch(
          authorizationFailed({
            error,
          })
        );
      });
  };

const AUTOLOGIN_BY_TOKEN = "DISPOSABLE_AUTOLOGIN_TOKEN";

export const tryAutoLogin =
  ({ accountId, token }) =>
  (dispatch, getState) => {
    const state = getState();
    const { fingerprint, locale } = registrationSelector(state);

    const credential = {
      type: AUTOLOGIN_BY_TOKEN,
      accountId,
      token,
    };

    dispatch(startAutologin());

    return dispatch(authorizeGuestV2({ accountId, token })).then(
      ({ loginResult }) =>
        autoLogin({
          clientCapabilities: CLIENT_CAPABILITIES,
          credential,
          fingerprint,
          locale,
          osVersion: getOsVersion(),
          clientVersion: GENERATED_APP_INFO.fullVersion,
          ...(loginResult === LoginResult.LOGGED_IN_LIMITED && {
            limitedSessionCredentials: { accountId, authToken: token },
          }),
        })
          .then((data) => {
            const {
              loginResult,
              errorMessage,
              resendDelay,
              accountId,
              newRegistration,
              ...connectionManagerData
            } = data;

            if (loginResult === LoginResult.UNKNOWN_ERROR) {
              dispatch(
                setAutoLoginResult(AutologinResult.AUTO_LOGIN_RESULT_FAILED)
              );

              return;
            }
            emitEvent(EventNames.TANGO_LOGIN);
            emitAF({
              eventName: EventNames.TANGO_LOGIN,
              eventCategory: EventCategory.LOGIN,
            });
            batch(() => {
              // order is important here! need to invalidate user-session-scope state first when swapping account
              dispatch(
                setAutoLoginResult(AutologinResult.AUTO_LOGIN_RESULT_SUCCESS)
              );
              dispatch(fetchSessionDetails());

              if (loginResult === LoginResult.LOGGED_IN_AS_GUEST) {
                dispatch(
                  authorizationSuccess({
                    accountId,
                    connectionManagerData,
                  })
                );

                return;
              }
              dispatch(
                loginSuccess({
                  accountId,
                  newRegistration,
                  isLimitedSession:
                    loginResult === LoginResult.LOGGED_IN_LIMITED,
                  connectionManagerData,
                  provider: LoginProvider.TANGO_DISPOSABLE_TOKEN,
                  clientCapabilities: CLIENT_CAPABILITIES,
                })
              );
            });

            return data;
          })
          .catch(() => {
            dispatch(
              setAutoLoginResult(AutologinResult.AUTO_LOGIN_RESULT_FAILED)
            );
          })
          .finally(() => {
            dispatch(validationEnd());
            cleanupQueryWithKeys(AUTOLOGIN_QUERY_KEYS);
          })
    );
  };

export const authorizeGuest = () => (dispatch, getState) => {
  const state = getState();
  const { hasValidAccount, isAuthorizationInProgress } =
    authorizeGuestSelector(state);

  if (hasValidAccount && !isAuthorizationInProgress) {
    dispatch(
      setMarketingVisitData({
        isVisitRequestNeeded: true,
      })
    );
  }

  if (hasValidAccount || isAuthorizationInProgress) {
    return Promise.resolve();
  }

  dispatch(authorizationStart());

  const { fingerprint, locale } = registrationSelector(state);

  return registerGuest({
    clientCapabilities: CLIENT_CAPABILITIES,
    fingerprint,
    locale,
    osVersion: getOsVersion(),
    clientVersion: GENERATED_APP_INFO.fullVersion,
  })
    .then(({ accountId, ...connectionManagerData }) => {
      dispatch(
        authorizationSuccess({
          accountId,
          connectionManagerData,
        })
      );
      dispatch(fetchSessionDetails());

      dispatch(
        setMarketingVisitData({
          isVisitRequestNeeded: true,
        })
      );

      return sendReferralRegistrationRequest(getState);
    })
    .catch((error) => {
      dispatch(
        authorizationFailed({
          error,
        })
      );
    });
};

export const prepareProviders = () => (dispatch, getState) => {
  const state = getState();
  const promises = [];

  if (
    loginSelectors.isPreparingProviders(state) ||
    loginSelectors.getAvailableProviders(state).length > 0
  ) {
    return Promise.resolve();
  }

  const onPreparationsEnd = () => dispatch(endedPreparingProviders());
  dispatch(beganPreparingProviders());

  return fetchAllowedCredentialTypes()
    .catch(() => Object.values(LoginProvider))
    .then((providers) => {
      const availableProviders = loginSelectors.getAvailableProviders(state);

      const loadProviderIfNeeded = (provider, loader) => {
        if (
          !providers.includes(provider) ||
          availableProviders.includes(provider)
        ) {
          return;
        }

        promises.push(
          loader().then(() => dispatch(loadedLoginProvider(provider)))
        );
      };

      loadProviderIfNeeded(LoginProvider.TANGO_PHONE_NUMBER, () =>
        Promise.resolve()
      );

      loadProviderIfNeeded(LoginProvider.TANGO_DISPOSABLE_TOKEN, () =>
        Promise.resolve()
      );

      getGoogleProviderEnabled() &&
        loadProviderIfNeeded(LoginProvider.GOOGLE, () => Promise.resolve());

      getFacebookProviderEnabled() &&
        loadProviderIfNeeded(LoginProvider.FACEBOOK, () => Promise.resolve());

      getAppleProviderEnabled() &&
        loadProviderIfNeeded(LoginProvider.APPLE, () => Promise.resolve());

      return Promise.all(promises);
    })
    .then(onPreparationsEnd, onPreparationsEnd);
};

export const shouldRetryLogin = ({ loginResult }) => {
  switch (loginResult) {
    case LoginResult.VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE:
    case LoginResult.METHOD_UNAVAILABLE_FOR_REGISTERED_USER:
    case LoginResult.VERIFICATION_REQUIRED:
    case LoginResult.WRONG_CODE:
    case LoginResult.LOGGED_IN:
    case LoginResult.LIMIT_REACHED:
    case LoginResult.METHOD_UNAVAILABLE:
      return false;
    case LoginResult.UNKNOWN_ERROR:
    case LoginResult.GUEST_UPGRADE_FAILED:
    default:
      return true;
  }
};

export const loginWithProviderSelectors = (state) => ({
  availableProviders: loginSelectors.getAvailableProviders(state),
  isAuthorized: loginSelectors.isAuthorized(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
  isLoginInProgress: loginSelectors.isLoginInProgress(state),
  fingerprint: deviceInfoSelectors.getDeviceFingerprint(state),
  locale: deviceInfoSelectors.getDeviceLocale(state),
  isMobile: deviceInfoSelectors.isAnyMobileDevice(state),
  deviceType: deviceInfoSelectors.getDeviceType(state),
  entryPoint: loginSelectors.getLoginEntryPoint(state),
});

export const loginWithProvider =
  ({ provider, onLoginSuccess = () => {}, screenType }) =>
  (dispatch, getState) => {
    const state = getState();
    const {
      availableProviders,
      isAuthorized,
      isLoggedIn,
      isLoginInProgress,
      fingerprint,
      locale,
      isMobile,
      deviceType,
      entryPoint,
    } = loginWithProviderSelectors(state);

    if (!availableProviders.includes(provider)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(`provider ${provider} is not ready`);
    }

    if (isLoggedIn || isLoginInProgress) {
      return Promise.resolve();
    }

    const obtainCredential = (provider, state, dispatch) => {
      const { phoneNumber, verificationCode, allowOnlySmsValidation } =
        loginSelectors.getPhoneNumberAuthenticationState(state);
      switch (provider) {
        case LoginProvider.TANGO_PHONE_NUMBER:
          return Promise.resolve({
            type: LoginProvider.TANGO_PHONE_NUMBER,
            phoneNumber,
            verificationCode,
            allowTc2Validation: !allowOnlySmsValidation,
          });
        case LoginProvider.FACEBOOK:
          return obtainFacebookCredential(dispatch);
      }
    };

    const attemptUpgradeGuest = async (credential) => {
      const body = {
        credential,
        fingerprint,
        locale,
        clientCapabilities: CLIENT_CAPABILITIES,
        osVersion: getOsVersion(),
        clientVersion: GENERATED_APP_INFO.fullVersion,
      };

      if (isAuthorized) {
        return upgradeGuest(body);
      }

      return registerVisitor({
        body,
        provider: mapProviderToApi[provider],
      });
    };

    const upgradeGuestWithRetry = (state) => async (credential) => {
      const { type, phoneNumber, verificationCode } = credential;
      if (type === LoginProvider.TANGO_PHONE_NUMBER) {
        const resendAllowedTimestamp = loginSelectors.getResendAllowedTimestamp(
          state,
          phoneNumber
        );
        if (
          typeof resendAllowedTimestamp === "number" &&
          typeof verificationCode !== "string" &&
          new Date() < resendAllowedTimestamp
        ) {
          const { codeProvider } =
            loginSelectors.getPhoneNumberAuthenticationState(state);

          return {
            loginResult: LoginResult.VERIFICATION_REQUIRED,
            // it's possible to resend SMS but not TANGO_CHAT message
            deliveryMethod: "SMS",
            provider: codeProvider,
            credential: { type: LoginProvider.TANGO_PHONE_NUMBER, phoneNumber },
          };
        }
      }

      const firstAttempt = await attemptUpgradeGuest(credential);
      if (!shouldRetryLogin(firstAttempt)) {
        analyticsUpgradeGuest(firstAttempt, credential?.type);

        return { ...firstAttempt, credential };
      }
      await dispatch(authorizeGuest(true));
      const secondAttempt = await attemptUpgradeGuest(credential);
      if (!shouldRetryLogin(secondAttempt)) {
        analyticsUpgradeGuest(secondAttempt, credential?.type);

        return { ...secondAttempt, credential };
      }
      // eslint-disable-next-line no-throw-literal
      throw "Guest upgrade failed";
    };

    dispatch(lockTopModal());
    dispatch(loginStart());

    return obtainCredential(provider, state, dispatch)
      .then(upgradeGuestWithRetry(state))
      .catch((error) => {
        if (isLockedByCaptcha(error)) {
          const { phoneNumber, allowOnlySmsValidation } =
            loginSelectors.getPhoneNumberAuthenticationState(state);

          dispatch(
            setCaptchaConfig({
              type: CaptchaType.PHONE,
              phoneNumber,
              fingerprint,
            })
          );

          retryAfterCaptcha(
            error,
            specifyPhoneNumberForPhoneLogin(phoneNumber)
          );

          if (entryPoint) {
            retryAfterCaptcha(error, loginSetEntryPoint(entryPoint));
          }

          if (allowOnlySmsValidation) {
            retryAfterCaptcha(error, askForSms());
          }

          if (
            ![
              SignUpEntryPoint.REGISTRATION,
              SignUpEntryPoint.REGPAGE_1,
            ].includes(entryPoint) &&
            (isMobile ||
              (deviceType === DeviceType.IPAD &&
                window.innerHeight > window.innerWidth))
          ) {
            retryAfterCaptcha(
              error,
              openLoginView({
                registrationSource: RegistrationSource.SELF_PROFILE_ICON,
                isShowPhoneNumberLoginFlow: true,
                screenType,
                onLoginSuccess,
              })
            );
          }

          retryAfterCaptcha(
            error,
            loginWithProvider({ provider, screenType, onLoginSuccess })
          );
        }

        dispatch(loginFailed(error));
        throw error;
      })
      .then(
        ({
          analyticsKey,
          loginResult,
          resendDelay,
          errorMessage,
          accountId,
          newRegistration,
          deliveryMethod,
          provider: codeProvider,
          ...connectionManagerData
        }) => {
          switch (loginResult) {
            case LoginResult.VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE:
              dispatch(phoneLoginVerificationRequiredSMSNotPossible());
            /* eslint-disable no-fallthrough */
            case LoginResult.VERIFICATION_REQUIRED:
              batch(() => {
                dispatch(
                  phoneLoginVerificationRequired({
                    codeProvider,
                    deliveryMethod,
                  })
                );
                if (typeof resendDelay === "number") {
                  dispatch(phoneLoginSetSmsResendDelay(resendDelay));
                }
              });
              break;
            case LoginResult.METHOD_UNAVAILABLE_FOR_REGISTERED_USER:
              dispatch(phoneLoginMethodUnavailableForRegisteredUser());
              break;
            case LoginResult.METHOD_UNAVAILABLE:
              dispatch(phoneLoginPhoneVerificationUnavailable());
              break;
            case LoginResult.LIMIT_REACHED:
              dispatch(phoneLoginAttemptLimitReached(errorMessage));
              break;
            case LoginResult.WRONG_CODE:
              dispatch(phoneLoginVerificationFailed(errorMessage));
              break;
            case LoginResult.LOGGED_IN:
              if (provider === LoginProvider.TANGO_PHONE_NUMBER) {
                emitEvent(EventNames.CODE_ENTERED, {
                  [EventFields.RESULT_CODE]: 1,
                  [EventFields.TANGO_SCREEN]: SCREEN_NAME.REGISTRATION_SIGN_IN,
                });
              }
              if (!newRegistration) {
                emitEvent(EventNames.TANGO_LOGIN);
                emitAF({
                  eventName: EventNames.TANGO_LOGIN,
                  eventCategory: EventCategory,
                });
              }

              if (!newRegistration && analyticsKey) {
                dispatch(updateAnalyticsKey({ analyticsKey }));
              }

              newRegistration && emitImpactSignUpCompleted(accountId);

              batch(() => {
                dispatch(
                  loginSuccess({
                    accountId,
                    newRegistration,
                    connectionManagerData,
                    provider,
                    clientCapabilities: CLIENT_CAPABILITIES,
                    entryPoint,
                  })
                );
                dispatch(fetchSessionDetails());
                dispatch(clearVisitorData());
                dispatch(
                  setDropdownAvatarState({
                    isAvatarMenuVisible: false,
                    shouldHideInstantly: true,
                  })
                );
              });

              if (typeof onLoginSuccess === "function") {
                onLoginSuccess();
              }

              return {
                accountId,
                ...sendReferralRegistrationRequest(getState),
              };
          }
        }
      )
      .then(() => dispatch(loadMyProfile()))
      .then(() => {
        batch(() => {
          dispatch(unlockTopModal());
          dispatch(
            dismissModalWithType({
              modalType: ModalType.LOGIN,
              modalDismissReason: FROM_FLOW,
            })
          );
          dispatch(
            addToast({
              type: ToastType.REGULAR,
              title: sharedMessages.loginSuccessMessage,
            })
          );
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(unlockTopModal());
          if (err.status === 412) {
            dispatch(
              dismissModalWithType({
                modalType: ModalType.LOGIN,
                modalDismissReason: FROM_FLOW,
              })
            );
            dispatch(openRegistrationDisabledModal());
          }
        });
      });
  };

export const logoutFromProvider = (provider) => {
  switch (provider) {
    case LoginProvider.TANGO_PHONE_NUMBER:
    case LoginProvider.TANGO_DISPOSABLE_TOKEN:
      return Promise.resolve();
    case LoginProvider.GOOGLE:
      return Promise.resolve();
    case LoginProvider.APPLE:
      return Promise.resolve();
    case LoginProvider.FACEBOOK:
      // https://developers.facebook.com/docs/facebook-login/manually-build-a-login-flow/#logout
      return Promise.resolve();
  }
};

export const loadProvider = (provider) => {
  switch (provider) {
    case LoginProvider.TANGO_PHONE_NUMBER:
    case LoginProvider.TANGO_DISPOSABLE_TOKEN:
    case LoginProvider.GOOGLE:
    case LoginProvider.APPLE:
    case LoginProvider.FACEBOOK:
      return Promise.resolve();
  }
};

export const onLogoutSuccess = (provider, reason) => (dispatch, getState) => {
  dispatch(logoutSuccess());
  dispatch(
    addToast({
      type: ToastType.REGULAR,
      title: sharedMessages.logoutSuccessMessage,
    })
  );
  emitReasonAction(reason);
  const handleLogoutError = (error) =>
    dispatch(failedToLogoutFromProvider({ error, provider }));
  if (provider === null) {
    return Promise.resolve();
  }
  if (loginSelectors.getAvailableProviders(getState()).includes(provider)) {
    return logoutFromProvider(provider).catch(handleLogoutError);
  }

  return loadProvider(provider)
    .then(() => logoutFromProvider(provider))
    .catch(handleLogoutError);
};

export const logout =
  ({ reason, shouldCheckInVisitor }) =>
  async (dispatch, getState) => {
    const state = getState();
    if (
      !loginSelectors.isLoggedIn(state) ||
      loginSelectors.isLogoutInProgress(state)
    ) {
      return;
    }
    const provider = loginSelectors.getLoggedInProvider(state);
    dispatch(logoutStart());
    cleanLocalStorageByKeys(LOCAL_STORAGE_KEYS);

    if (shouldCheckInVisitor) {
      batch(() => {
        dispatch(onLogoutSuccess(provider, reason));
        dispatch(
          setDropdownMenuState({
            isAvatarMenuVisible: false,
            isBurgerMenuVisible: false,
            shouldHideInstantly: true,
          })
        );
      });

      await dispatch(checkInVisitor());

      return;
    }

    try {
      dispatch(authorizationStart());

      const { accountId, newRegistration, ...connectionManagerData } =
        await registerGuest({
          clientCapabilities: CLIENT_CAPABILITIES,
          fingerprint: deviceInfoSelectors.getDeviceFingerprint(state),
          locale: deviceInfoSelectors.getDeviceLocale(state),
          osVersion: getOsVersion(),
          clientVersion: GENERATED_APP_INFO.fullVersion,
        });

      batch(() => {
        dispatch(onLogoutSuccess(provider, reason));
        dispatch(
          setDropdownMenuState({
            isAvatarMenuVisible: false,
            isBurgerMenuVisible: false,
            shouldHideInstantly: true,
          })
        );
        dispatch(authorizationStart());
        dispatch(
          authorizationSuccess({
            accountId,
            connectionManagerData,
          })
        );
        dispatch(fetchSessionDetails());
      });
    } catch (err) {
      if (err && err.status === HTTP_CODE_FORBIDDEN) {
        return dispatch(onLogoutSuccess(provider, EventReasons.ERROR));
      }

      return dispatch(logoutFailed());
    }
  };

export const tryValidation =
  ({ locale, limitedSessionCredentials = null, deviceToken }) =>
  (dispatch) =>
    validation({
      locale,
      osVersion: getOsVersion(),
      limitedSessionCredentials,
      deviceToken,
    })
      .then(({ isSessionLimited }) => {
        dispatch(
          setSessionLimited({
            isSessionLimited: !!isSessionLimited,
          })
        );
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log("Validation failure: ", err);

        if (err.status === HTTP_CODE_UNAUTHORIZED) {
          dispatch(refreshSessionToken());
        }
      })
      .finally(() => {
        dispatch(validationEnd());
        cleanupQueryWithKeys(AUTOLOGIN_QUERY_KEYS);
      });
