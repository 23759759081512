import { getNftAuctionsList } from "api/nft";
import { getNftAuctionCurrentId } from "src/environment";
import { nftAuctionSelectors } from "state/selectors";
import { auctionsActionCreators } from "state/tree/nft";
import { ResponseCode } from "ui/nft/enums";

export default () => async (dispatch, getState) => {
  const state = getState();
  const auctionCurrentId = getNftAuctionCurrentId();

  if (nftAuctionSelectors.isAuctionsLoading(state) || !auctionCurrentId) {
    return;
  }

  dispatch(auctionsActionCreators.fetchBegan());
  try {
    const { code, auctions } = await getNftAuctionsList([auctionCurrentId]);

    if (code !== ResponseCode.OK) {
      throw new Error("Network error");
    }

    dispatch(
      auctionsActionCreators.fetchCompleted({
        data: { auctions, auctionCurrentId },
        replaceAll: false,
      })
    );
  } catch (e) {
    dispatch(
      auctionsActionCreators.fetchFailed({ error: e, removeAll: false })
    );
  }
};
