import { enhancedFetch } from "src/features/signin/imports/api";
import { CLIENT_CAPABILITIES } from "src/features/signin/imports/constants";
import { getProxycadorHost } from "src/features/signin/imports/environment";

export const validation = ({
  locale,
  osVersion,
  limitedSessionCredentials,
  deviceToken,
}) =>
  enhancedFetch(`${getProxycadorHost()}/proxycador/api/public/v1/validation`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      locale,
      osVersion,
      ...(!!limitedSessionCredentials && { limitedSessionCredentials }),
      ...(!!deviceToken && { deviceToken }),
      clientCapabilities: CLIENT_CAPABILITIES,
      clientVersion: GENERATED_APP_INFO.fullVersion,
    }),
  }).then((resp) => resp.json());
