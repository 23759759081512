import { useCallback, useEffect } from "react";
import { nftAuctionSelectors } from "state/selectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import loadAuctionsList from "state/flows/nft/loadAuctionsList";
import {
  getNftAuctionCurrentId,
  getNftAuctionLastHourStalePeriod,
  getNftAuctionStalePeriod,
} from "environment";
import { auctionsActionCreators } from "state/tree/nft";
import useInterval from "ui/hooks/useInterval";
import { HOUR } from "src/constants";
import useAuctionEnabled from "ui/nft/hooks/useAuctionEnabled";

export const useAuctionStalePeriod = (callback, millisLeft) => {
  const stalePeriod = getNftAuctionStalePeriod();
  const lastHourStalePeriod = getNftAuctionLastHourStalePeriod();
  const interval =
    millisLeft && millisLeft < HOUR ? lastHourStalePeriod : stalePeriod;
  return useInterval(callback, interval);
};

export default ({ withRefresh = false }) => {
  const dispatch = useDispatch();
  const isAuctionEnabled = useAuctionEnabled();
  const auctionConfig = useSelector(
    nftAuctionSelectors.getAuction,
    shallowEqual
  );
  const auctionConfigStale = useSelector(nftAuctionSelectors.getAuctionStale);

  useEffect(() => {
    isAuctionEnabled && auctionConfigStale && dispatch(loadAuctionsList());
  }, [isAuctionEnabled, auctionConfigStale]);

  const setAuctionStale = useCallback(() => {
    if (!withRefresh || !isAuctionEnabled || !getNftAuctionCurrentId()) {
      return;
    }

    dispatch(auctionsActionCreators.setAuctionStale());
  }, []);

  useAuctionStalePeriod(setAuctionStale, auctionConfig?.millisLeft);

  return auctionConfig;
};
