const createUnauthorizedErrorHandler = () => () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith("persist:")) {
      localStorage.removeItem(key);
    }
  }, {});
  window.location.reload();
};

export default createUnauthorizedErrorHandler;
