import { emitUiAction } from "@analytics/emitUiAction";
import {
  Comment,
  EventFields,
  RegistrationSource,
  SCREEN_NAME,
  SIGNIN_TARGET,
} from "@analytics/enums";
import { AdditionalParamsObject } from "@analytics/types";

export enum HomePageTarget {
  ABOUT_US = "about_us",
  AFFILIATE_PROGRAM = "affiliate_program",
  AGENCY_PROGRAM = "agency_program",
  ARTISTS_COMMUNITY = "artists_community",
  CATEGORY_AFRICA = "category_africa",
  CATEGORY_ARTISTS = "category_artists",
  CATEGORY_ASIA = "category_asia",
  CATEGORY_AUDIO_ROOMS = "category_audio_rooms",
  CATEGORY_FLIRTY = "category_flirty",
  CATEGORY_INDIA = "category_india",
  CATEGORY_LATIN_AMERICA = "category_latin_america",
  CUSTOMER_SUPPORT = "customer_support",
  GAMES = "games",
  GET_TANGO_APP = "get_tango_app",
  LANGUAGE = "language",
  LEADERBOARD = "leaderboard",
  LEGAL_INFORMATION = "legal_information",
  LIVE_STREAM_COVER = "live_stream_cover",
  LIVESTREAMS = "livestreams",
  LOG_IN = "log_in",
  LOYALTY_PROGRAM = "loyalty_program",
  NEXT_STREAM = "next_stream",
  PREVIOUS_STREAM = "prev_stream",
  REGISTRATION_MORE_OPTIONS = "registration_more_options",
  RESELLER_PROGRAM = "reseller_program",
  SHOW_MORE_CREATORS = "show_more_creators",
  SIGNIN_WITH_APPLE = SIGNIN_TARGET.SIGNIN_WITH_APPLE,
  SIGNIN_WITH_GOOGLE = SIGNIN_TARGET.SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_PHONE = SIGNIN_TARGET.SIGNIN_WITH_PHONE,
  SUBMENU = "submenu",
  TANGO_LOGO = "tango_logo",
  VIEW_ALL_GAMES = "view_all_games",
}

export interface HomePageParams {
  additionalParams?: AdditionalParamsObject;
  reportedName?:
    | SCREEN_NAME.MAIN_HOME_PAGE
    | SCREEN_NAME.MAIN_HOME_PAGE_V2
    | SCREEN_NAME.MAIN_HOME_PAGE_V3;
  target: HomePageTarget;
}

export const emitHomePageClickAction = ({
  target,
  additionalParams = {},
  reportedName = SCREEN_NAME.MAIN_HOME_PAGE,
}: HomePageParams) => {
  emitUiAction({
    additionalParams,
    tango_screen: { reportedName },
    target,
  });
};

export interface AdditionalAnalyticsParams {
  [EventFields.COMMENT]?: Comment;
}

interface EmitHomePageSignInClickParams {
  additionalParams: AdditionalAnalyticsParams;
  screenName?:
    | SCREEN_NAME.MAIN_HOME_PAGE
    | SCREEN_NAME.MAIN_HOME_PAGE_V2
    | SCREEN_NAME.MAIN_HOME_PAGE_V3;
  target:
    | HomePageTarget.LOG_IN
    | HomePageTarget.SIGNIN_WITH_APPLE
    | HomePageTarget.SIGNIN_WITH_GOOGLE
    | HomePageTarget.SIGNIN_WITH_PHONE;
}

export const emitHomePageSignInClick = ({
  target,
  additionalParams,
  screenName = SCREEN_NAME.MAIN_HOME_PAGE,
}: EmitHomePageSignInClickParams) => {
  let source = RegistrationSource.HOME_PAGE;

  if (screenName === SCREEN_NAME.MAIN_HOME_PAGE_V2) {
    source = RegistrationSource.HOME_PAGE_V2;
  }

  if (screenName === SCREEN_NAME.MAIN_HOME_PAGE_V3) {
    source = RegistrationSource.HOME_PAGE_V3;
  }

  emitHomePageClickAction({
    target,
    additionalParams: {
      [EventFields.SOURCE]: source,
      ...additionalParams,
    },
    reportedName: screenName,
  });
};
