import { createAsyncThunk } from "@reduxjs/toolkit";
import { CountriesState } from "state/tree/countries";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { RootState } from "state/delegate";
import { fetchCountriesForSignIn } from "src/api/countries";
import { isApiError } from "src/api/utils/enhancedFetch";

export const loadCountriesForSignIn = createAsyncThunk<
  Data<CountriesState>,
  FetcherMetaV2 | void,
  { state: RootState; rejectValue: string }
>("lwc/countries/fetchCountriesForSignIn", async (_, api) => {
  try {
    return await fetchCountriesForSignIn();
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;
    return api.rejectWithValue(error);
  }
});
