import once from "lodash.once";
import { ExperimentIds } from "@analytics/enums";
import ApiError from "api/utils/apiError";
import { DAY, MINUTE, TANGO_ACCOUNT_ID } from "src/constants";
import { Nullable } from "src/types/common";
import {
  CountryFilterType,
  DateFilterType,
  EmptyArgsFn,
  FeatureControlProperties,
} from "ui/hooks/useFeatureEnabled/types";
import qrCodeImageUrlHomePageV3 from "img/homePage/home-page-qr.svg?url";
import qrCodeImageUrlHomePageV2 from "img/homePage/home-page-qr-v2.svg?url";
import qrCodeImageUrlHomePage from "img/homePage/pic-qr-gradient.svg?url";
import qrCodeImageUrl from "img/landings/get_the_app/qr_code.svg?url";

let rawEnvironment: Record<string, string> | undefined; // no default initialization to catch misuse errors

let hooks = {};
const getByKey =
  (key: string, defaultValue = "") =>
  () =>
    rawEnvironment?.[key] || defaultValue;
const getNumberByKey =
  (key: string, defaultValue = 0) =>
  () =>
    Number(rawEnvironment?.[key] || defaultValue);
const getBoolByKey =
  (key: string, defaultValue = false) =>
  () => {
    const value = rawEnvironment?.[key];

    return value ? !!JSON.parse(value) : defaultValue;
  };
const getArrayByKey =
  (key: string, defaultValue: string[] = []) =>
  () => {
    if (!rawEnvironment?.[key]) {
      return defaultValue;
    }

    return rawEnvironment[key].split(",").filter((x) => x);
  };
const getDateByKey =
  (key: string, defaultValue: Nullable<Date> = null) =>
  () => {
    const dateStr = rawEnvironment?.[key];

    return dateStr ? new Date(dateStr) : defaultValue;
  };

const generateFeatureControlProperties = (
  prefix: string
): FeatureControlProperties => ({
  enabled: getBoolByKey(`${prefix}.enabled`),
  guest: getBoolByKey(`${prefix}.guest.enabled`, true),
  country: getArrayByKey(`${prefix}.countries`, ["*"]),
  countryFilter: getByKey(
    `${prefix}.countries.filter`,
    CountryFilterType.INCLUDE
  ) as EmptyArgsFn<CountryFilterType>,
  registerDateEnabled: getBoolByKey(`${prefix}.registerDate.enabled`, false),
  registerDateFilter: getByKey(
    `${prefix}.registerDate.filter`,
    DateFilterType.BEFORE
  ) as EmptyArgsFn<DateFilterType>,
  registerDate: getDateByKey(`${prefix}.registerDate`),
});

export const leaderboard = generateFeatureControlProperties("leaderboard");
export const userSearch = generateFeatureControlProperties("userSearch");
export const forYouTab = generateFeatureControlProperties("forYouTab");
export const premium = generateFeatureControlProperties("premium");

export const getAppleClientId = getByKey("apple.client.id");
export const getSessionTokenLifeTimeCheckInterval = getByKey(
  "session.token.lifetime.check.interval",
  `${MINUTE}`
);
export const getSessionTokenTimeToRefreshBeforeExpiration = getByKey(
  "session.token.time.to.refresh.before.expiration",
  `${MINUTE * 2}`
);

export const getEnvironmentName = getByKey("name");
export const getPersonalGiftsHost = getByKey("personalGifts.url");
export const getGoogleClientId = getByKey("google.client.id");
export const getGaTrackingId = getByKey("google.tracking.id");
export const getGaTrackingEnabled = getBoolByKey("google.tracking.enabled");
export const getGtmId = getByKey("google.gtm.id");
export const getGtmLoadDelay = getNumberByKey("google.gtm.load.delay.ms", 0);
export const getTTQId = getByKey("tiktok.analytics.id");
export const isLpSoundEnabled = getBoolByKey("lp.sound.enable");
export const isLpTranslationEnabled = getBoolByKey(
  "enabled.landing.translation",
  false
);
export const getEnabledBeautifulLink = getBoolByKey(
  "beautiful.link.enabled",
  false
);
export const getFacebookAppId = getByKey("facebook.app.id");
export const getFacebookProviderEnabled = getBoolByKey(
  "facebook.provider.enabled",
  true
);
export const getGoogleProviderEnabled = getBoolByKey(
  "google.provider.enabled",
  true
);
export const getAppleProviderEnabled = getBoolByKey(
  "apple.provider.enabled",
  false
);
export const getEnabledPaymentProviders = getArrayByKey("purchase.providers");
export const isWebviewEnabled = getBoolByKey("webview");
export const isTopStreamersEnabled = getBoolByKey("top.streamers.enabled");
export const isTopStreamerForGuestEnabled = getBoolByKey(
  "top.streamers.for.guest.enabled"
);
export const enabledLandscapeModeAndScreenShare = getBoolByKey(
  "landscapeMode.screenShare.enabled",
  false
);
export const getPartnershipLink = getByKey("partnership.program.link");
export const getResellerLink = getByKey("reseller.program.link");
export const getAffiliateLink = getByKey("affiliate.program.link");
export const getAboutUsPageLink = getByKey("about-us.page.link");
export const getCheckoutId = getByKey(
  "checkout.id",
  "pk_test_809a15c3-74d5-4100-b2e4-1af58a9ffe8c"
);
export const getEnabledCheckoutAdditionalProviders = getArrayByKey(
  "checkout.additional.providers"
);
export const getCheckoutCountryCodesWithZip = getArrayByKey(
  "checkout.cc.to.require.zip"
);
export const getSafeChargeEnvironment = getByKey("safecharge.environment");
export const isSafeChargeSessionTokenEnabled = getBoolByKey(
  "safecharge.session.token.enabled",
  false
);
export const getSafeChargeMerchantId = getByKey("safecharge.merchant.id");
export const getSafeChargeMerchantSiteId = getByKey(
  "safecharge.merchant.site.id"
);
export const getSafeChargeUsMerchantId = getByKey("safecharge.us.merchant.id");
export const getSafeChargeUsMerchantSiteId = getByKey(
  "safecharge.us.merchant.site.id"
);
export const getTangoApms = getArrayByKey("tango.apms");
export const getJoshApms = getArrayByKey("josh.apms");
export const getTangoApmOffersVersion = getByKey("tango.apm.offers.version");
export const getApplePayVersion = getNumberByKey("apple_pay.version");
export const getApplePayMinSupportsVersion = getNumberByKey(
  "apple_pay.supports_version.min",
  6
);
export const getApplePayMaxSupportsVersion = getNumberByKey(
  "apple_pay.supports_version.max",
  14
);
export const getIsGoogleProduction = getBoolByKey(
  "google_pay.environment.production",
  false
);
export const getGooglePayPublicKey = getByKey("google_pay.public_key");
export const getGooglePayMerchantId = getByKey("google_pay.merchant_id");
export const getApmsSasAvailable = getArrayByKey("apms.sas");
export const getWebviewPaymentResultRedirectApms = getArrayByKey(
  "webview.payment.result.redirect.apms"
);
export const getWebviewPaymentFinalPurchaseRedirectApms = getArrayByKey(
  "webview.payment.final-purchase.redirect.apms"
);
export const getIsEnabledGiftingWithDiamonds = getBoolByKey(
  "diamonds.gifting.enabled"
);
export const getDefaultLinkToGetBackToApp = getByKey("back.to.app.link");
export const getIsChatMediaEnabled = getBoolByKey("chat.media.enable");
export const getChatMaxMediaSize = getNumberByKey(
  "chat.max.media.size.bytes",
  20971520 // 20 Mb
);
export const getIsChatDisabled = getBoolByKey("chat.disable", true);
export const getAbTestGroupOverride = (experimentId: ExperimentIds) =>
  getNumberByKey(`localAbTests.${experimentId}.groupOverride`, -1)();
export const getAbTestGroupAmount = (
  experimentId: ExperimentIds,
  defaultValue = 2
) => getNumberByKey(`localAbTests.${experimentId}.groupAmount`, defaultValue)();
export const getChatPollPeriodMs = getNumberByKey("chat.poll.period.ms");
export const getValidationPollPeriodMs = getNumberByKey(
  "validation.poll.period.ms",
  300000
);
export const getLogAnalytics = getBoolByKey("analytics.console.log", true);
export const getShouldLogHls = getBoolByKey("hls.console.log", true);
export const getAntMediaServerWebsocketUrl = getByKey(
  "broadcast.ant-media-server.websocket.url"
);
export const buildWebsocketUrl = (host: string) =>
  `wss://web.cm.tango.me/?host=${host}&port=443`;
export const getHooks = () => hooks;
export const getLhlsEnabled = getBoolByKey("lhls.enable");
export const getHdVideoEnabled = getBoolByKey("hd.video.enable");
export const getIntercomAppId = getByKey("intercom.app.id");
export const getIsMultiStreamEnabled = getBoolByKey("multistream.enable", true);
export const getIsResilientToDomMutationsWorkaroundEnabled = getBoolByKey(
  "resilient.to.dom.mutations",
  true
);

export const getInstagramAppId = getByKey(
  "instagram.app.id",
  "2729735720677121"
);

export const getChatIdForLoadLandingsFromShouts = getByKey(
  "chatId.getShoutsForLoadLandings",
  TANGO_ACCOUNT_ID
);

export const getShowChatWithTangoDiscount = getBoolByKey(
  "show.chat.tangoDiscount",
  false
);

export const isMoveOneToOneFromChatEnabled = getBoolByKey(
  "move.oneToOne.enabled",
  true
);

export const getInstagramPostsCacheExpirationMs = getNumberByKey(
  "instagram.posts.cache.expire.ms",
  600000
);

export const getInstagramConnectEnabled = getBoolByKey(
  "instagram.connect.enable",
  false
);
export const getInstagramEnabled = getBoolByKey("instagram.enable", false);

export const getValidateBroadcastPicture = getBoolByKey(
  "broadcast.validate.picture",
  true
);

export const getIsPersonalGiftsEnabled = getBoolByKey("personalGifts.enabled");

export const getMintrouteBannerEnabledCountries = getArrayByKey(
  "mintroute.banner.enabled.countries"
);

export const getMintrouteOffersVersion = getByKey(
  "mintroute.offers.version",
  "mintroute"
);

export const getLiveTabByNameEnabling = (tabName: string) =>
  getBoolByKey(`live.tab.${tabName}.enabled`)();

export const getRecaptchaSiteKey = getByKey("recaptcha.sitekey");

export const getCaptchaLockingEnabled = getBoolByKey("captcha.locking.enable");

export const getLikeButtonEnabled = getBoolByKey("like.button.enabled");

export const getGuestFollowEnabled = getBoolByKey(
  "guest.follow.enabled",
  false
);

export const getCashierOffersVersion = getByKey("cashier.offers.version");

export const getLandingRedirectLink = getByKey("landing.logout.redirect.link");

export const getVideoTokenEnabled = getBoolByKey("video.token.enabled", true);
export const getVideoTokenPeriod = getNumberByKey("video.token.period", 5000);
export const getUseRichFragmentsFromCdn = getBoolByKey(
  "rich.fragment.cdn",
  false
);

export const getAppSettingsOptions = getArrayByKey("app.settings.options");

export const getNftAuctionEnabled = getBoolByKey("nft.auction.enabled");
export const getNftAuctionAllowedIds = getArrayByKey("nft.auction.allowed.ids");
export const getNftAuctionRemoveLotEnabled = getBoolByKey(
  "nft.auction.remove.lot.enabled"
);
export const getNftAuctionStalePeriod = getNumberByKey(
  "nft.auction.stale_period",
  10000
);
export const getNftAuctionLastHourStalePeriod = getNumberByKey(
  "nft.auction.last_hour.stale_period",
  1500
);
export const getNftAuctionTags = getArrayByKey("nft.auction.tags");
export const getNftAuctionTagLabel = (tag: string) =>
  getByKey(`nft.auction.tags.label.${tag}`);

export const getNftAuctionCardsPerPage = getNumberByKey(
  "nft.auction.cards.per.page",
  25
);

export const getNftAuctionCurrentId = getByKey("nft.auction.current.id");

export const getNftAuctionStartProlongation = getNumberByKey(
  "nft.auction.start.prolongation",
  3000
);

export const getNftMarketplaceEnabled = getBoolByKey(
  "nft.marketplace.enabled",
  false
);
export const getNftAuctionLabelById = (id: string) =>
  getByKey(`nft.auction.${id}.label`, "Tango Cards Auction")();

export const getFirebaseAppDeeplink = getByKey("firebase.app.deeplink");

export const getCashierSasEnabled = getBoolByKey("cashier.sas.enabled");

export const getArtistsPageEnabled = getBoolByKey(
  "artists.page.enabled",
  false
);

export const getShowQrCodeEnabled = getBoolByKey("web.qrcode.show", false);

export const getCountryPickerDebounceMs = getNumberByKey(
  "countryPicker.debounce.ms",
  1000
);

export const getAvoidBlockingDomainPart = getByKey(
  "avoidBlocking.domain.part",
  "gslbsolutions"
);
export const getTangoPaymentUrl = getByKey(
  "tangoPayment.url",
  "https://tokenization.psp.tangome.cloud/web-sdk"
);
export const getTangoPaymentAvoidBlockingUrl = getByKey(
  "tangoPayment.avoidblocling.url",
  "https://tokenization.psp.gslbsolutions.com/web-sdk"
);
export const getTangoPaymentScriptName = getByKey(
  "tangoPayment.scriptName",
  "TangoPayment.js"
);
export const getTangoPaymentCardNameMaxLength = getNumberByKey(
  "tangoPayment.card.name.maxLength",
  64
);

export const getForFansFeedRefreshDelay = getNumberByKey(
  "forFans.feed.refresh.delay",
  2000
);

export const getSkeletonErrorEveryMs = getNumberByKey(
  "skeleton.error.every.ms",
  5000
);
export const getPaymentLoadingErrorEveryMs = getNumberByKey(
  "payment.loading.error.every.ms",
  5000
);
export const getSinglePaymentMethodAutoclick = getBoolByKey(
  "singlePaymentMethod.autoclick"
);

export const getPaymentPreselectApm = getBoolByKey("payment.preselect.apm");

export const getCurrenciesWithSeparateTaxFromAmount = getArrayByKey(
  "currencies.separate.tax"
);

export const getLandingCardType = getByKey("landing.card.type");
export const getReferralRegistrationEnabled = getBoolByKey(
  "referral.registration.enabled",
  true
);

export const getDatadogEnabled = getBoolByKey("datadog.enabled");
export const getDatadogApiKey = getByKey("datadog.api.key");
export const getDatadogSite = getByKey("datadog.site");
export const getDatadogEnv = getByKey("datadog.env");
export const getSendErrorsToDatadog = getBoolByKey("datadog.send.errors");
export const getDatadogRUMEnabled = getBoolByKey("datadog.rum.enabled");
export const getDatadogApplicationId = getByKey("datadog.application.id");
export const getDatadogSessionSampleRate = getNumberByKey(
  "datadog.session.sample.rate"
);
export const getDatadogSessionReplaySampleRate = getNumberByKey(
  "datadog.session.replay.sample.rate"
);
export const getDatadogAccountIdsWhitelist = getArrayByKey(
  "datadog.account.ids.whitelist"
);

export const getBugBountyPageEnabled = getBoolByKey("bug.bounty.page.enabled");

export const getPeopleSearchMinQueryLength = getNumberByKey(
  "people.search.query.min.length",
  0
);

export const getJoshGoLiveLink = getByKey("josh.go-live.link");

export const getTransactionOutdatedTimeoutMs = getNumberByKey(
  "payment.transaction.outdated.timeout.ms",
  5 * DAY
);

export const getReceivedAcmeMessageDelay = getNumberByKey(
  "acme.received.message.interval.ms",
  MINUTE
);

let httpHttpsReplacementList: string[][] = [];

// list of pairs prefixes like [http, https]
export const getHttpHttpsReplacementList = () => httpHttpsReplacementList;

const hookLoaders = {
  production: () => import("hooks/production"),
  staging: () => import("hooks/staging"),
};

type HookLoaderKey = keyof typeof hookLoaders;

const isHookLoaderKey = (key: HookLoaderKey | string): key is HookLoaderKey =>
  hookLoaders[key as HookLoaderKey] !== undefined;

export const getProxycadorHost = () => {
  if (
    window.location.hostname.indexOf("tango") === -1 &&
    process.env.NODE_ENV === "production"
  ) {
    return getByKey(
      "proxycador.rest.mirror.host",
      "https://proxycador-fut-1.gslbsolutions.com"
    )();
  }

  return getByKey("proxycador.rest.host")();
};

export const loadEnvironment = once(() =>
  fetch("./local.properties", { cache: "no-store" })
    .then((resp) => {
      if (resp.ok) {
        return resp.text();
      }
      throw new ApiError({ status: resp.status, statusText: resp.statusText });
    })
    .then((text) => {
      // assuming our .properties file consists of simple things like x=y; if it's more advanced, "@js.properties/properties" can be used
      rawEnvironment = text
        .trim()
        .split(/\s*[\r\n]+\s*/g)
        .reduce(
          (env, line) => {
            if (line.startsWith("#")) {
              return env;
            }
            const [key, value] = line.split(/=(.*)/).map((x) => x.trim());
            if (key) {
              env[key] = value;
            }

            return env;
          },
          {} as Record<string, string>
        );
      const environmentName = getEnvironmentName();
      const hookLoader = isHookLoaderKey(environmentName)
        ? hookLoaders[environmentName]
        : hookLoaders.staging;

      const protocolReplaceHttp =
        rawEnvironment["protocol_replace.prefix.http"];
      const protocolReplaceHttps =
        rawEnvironment["protocol_replace.prefix.https"];
      if (protocolReplaceHttp && protocolReplaceHttps) {
        const httpsItems = protocolReplaceHttps.split(",");
        httpHttpsReplacementList = protocolReplaceHttp
          .split(",")
          .map((x, index) => [x, httpsItems[index]]);
      }

      return hookLoader().then(({ default: environmentHooks }) => {
        hooks = environmentHooks;
      });
    })
);

export const ensureLoadedEnvironment = <T>(getter: () => T): Promise<T> =>
  loadEnvironment().then(getter);

export const patchEnvironment = (key: string, value: string) => {
  if (rawEnvironment !== undefined) {
    rawEnvironment[key] = value;
  }
};

export const getTheAppBannerEnabled = getBoolByKey("get.app.banner.enabled");

export const isDevelopmentEnvironment = () =>
  process.env.NODE_ENV === "development";

export const getRealtimeRecommendationsInterval = getNumberByKey(
  "realtime.recommendations.interval",
  2000
);

export const getRealtimeRecommendationsRetryAttempts = getNumberByKey(
  "realtime.recommendations.retryAttempts",
  3
);

export const getGetTheAppAppGalleryUrl = getByKey(
  "get.the.app.app.gallery.url",
  "https://appgallery.huawei.com/app/C105350361"
);
export const getGetTheAppAppStoreUrl = getByKey(
  "get.the.app.app.store.url",
  "https://click.tango.me/RCIH/xf4k0kwg"
);
export const getGetTheAppGooglePlayUrl = getByKey(
  "get.the.app.google.play.url",
  "https://click.tango.me/RCIH/1axdvv31"
);
export const getGetTheAppQrCodeImageUrl = getByKey(
  "get.the.app.qr.code.image.url",
  qrCodeImageUrl
);
export const getGetTheAppAndroidApkUrl = getByKey(
  "get.the.app.download.android.apk.url",
  "https://resources.tango.me/web/tango-android-8.56.1716210069-arm64.apk"
);
export const getSnapchatPixelId = getByKey("snapchat.pixel.id");
export const getFinanceMfeUrl = getByKey("finance.mfe.url");

export const getHomePageQrCodeImageUrl = getByKey(
  "get.the.app.qr.code.image.url",
  qrCodeImageUrlHomePage
);

export const getHomePageV3QrCodeImageUrl = getByKey(
  "home.page.v3.qr.code.image.url",
  qrCodeImageUrlHomePageV3
);

export const getHomePageV2QrCodeImageUrl = getByKey(
  "home.page.v2.qr.code.image.url",
  qrCodeImageUrlHomePageV2
);

export const getHomePageV2GooglePlay = getByKey(
  "home.page.v2.googleplay.link",
  "https://click.tango.me/RCIH/1c2o7gja"
);

export const getHomePageV2AppStoreLink = getByKey(
  "home.page.v2.appstore.link",
  "https://click.tango.me/RCIH/q1ireoab"
);

export const getHomePageV3StoresLink = getByKey(
  "home.page.v3.googleplay.link",
  "https://click.tango.me/RCIH/feho64gc"
);

export const getHomePageV3AppStoreLink = getByKey(
  "home.page.v3.appstore.link",
  "https://click.tango.me/RCIH/2hnqulu9"
);

export const getTwitterPurchaseId = getByKey(
  "get.twitter.purchase.id",
  "tw-ocsn3-omfzh"
);
export const getTwitterRegistrationId = getByKey(
  "get.twitter.registration.id",
  "tw-ocsn3-omfzo"
);

export const getLatestBroadcasterAgreementDate = getByKey(
  "broadcaster.agreement.date",
  "13.08.2024"
);
