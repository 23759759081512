import { EventNames } from "@analytics/enums";

export const STATUS_TO_EVENT_MAP: Record<number, EventNames> = {
  1: EventNames.PURCHASE_SUCCESS,
  2: EventNames.PURCHASE_FAILED,
};

export const TRANSACTIONS_DATA_KEY = "transactionsData";

export const TRANSACTION_STATUS_KEY = "transactionStatus";
