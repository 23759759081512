import { createAsyncThunk } from "@reduxjs/toolkit";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { RootState } from "state/delegate";
import {
  fetchNearbyCountries,
  updateNearbyCountries,
} from "api/nearbyCountries";
import { getLocaleCountryCode } from "state/selectors";
import { isApiError } from "api/utils/enhancedFetch";
import { NearbyCountriesState } from "state/types";
import { refresh } from "state/flows/loadLiveStreamsFeeds";
import { LiveFeedType } from "src/enums";

export const loadNearbyCountries = createAsyncThunk<
  NearbyCountriesState["data"],
  FetcherMetaV2 | void,
  { state: RootState; rejectValue: string }
>("lwc/nearbyCountries/fetch", async (args, api) => {
  try {
    const locale = getLocaleCountryCode(api.getState());
    const data = await fetchNearbyCountries(locale);
    return data.reduce(
      (map, country) => {
        map[country.isoCode] = country;
        return map;
      },
      {} as NearbyCountriesState["data"]
    );
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;
    return api.rejectWithValue(error);
  }
});

export const updateActiveCountries = createAsyncThunk<
  void,
  string[],
  { state: RootState }
>("lwc/nearbyCountries/apply", async (activeCodes, api) => {
  try {
    await updateNearbyCountries(activeCodes);
    await api.dispatch(loadNearbyCountries());
    await api.dispatch(refresh(LiveFeedType.NEARBY));
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;
    return api.rejectWithValue(error);
  }
});
