import { useAccountIdMatch } from "ui/hooks/useFeatureEnabled";
import {
  getNftAuctionAllowedIds,
  getNftAuctionEnabled,
  getNftMarketplaceEnabled,
} from "environment";

export default () => {
  const auctionEnabled = getNftAuctionEnabled();
  const marketplaceEnabled = getNftMarketplaceEnabled();
  const canSeeAuction = useAccountIdMatch(getNftAuctionAllowedIds);

  return (auctionEnabled || marketplaceEnabled) && canSeeAuction;
};
